import React, { Component } from 'react';

class Main extends Component {

  render() {
    return (

      <div id="content"
      >
        <h2>Claim RAMP Token</h2>
        <h5>This Platform is for claiming RAMP Tokens ONLY. Please do not send any funds here. </h5>
        <p>&nbsp;</p>
        <table className="table">
          <tbody id="contentList">
            { this.props.products.map((product, key) => {
              return(
                <tr key={key}>
                  <td>Amount available for claiming: </td>
                  <td>{window.web3.utils.fromWei(product.amount.toString(), 'Ether')} RAMP</td>
                  <td>
                      <button
                          value={product.amount}
                          onClick={(event) => {
                            this.props.executeClaimToken()
                          }}
                        >
                          Claim RAMP Token
                        </button>
                    </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Main;
